.upload-wrapper {
  display: flex;
  justify-content: center;
}

.input-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: 1px dashed rgb(208, 201, 201);
  height: 100px;
  width: 100px;
  padding: 10px;
}

.input-label:hover {
  cursor: pointer;
  border: 1px dashed #5138cf;
}

.input-label span {
  margin-top: 5px;
}

.selected-files {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  /* margin-bottom: 10px; */
  flex: 0 1 calc(50% - 10px);

  @media screen and (max-width: 768px) {
    flex: 0 1 calc(100% - 10px);
  }
}

.file-item img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 5px;
}

.file-item span {
  font-size: 12px;
  word-wrap: break-word;
}

.file-item button {
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}

.file-item button:hover {
  background-color: #d43f21;
}

button {
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.preview-image {
  max-height: 100%;
  max-width: 100%;
}

.tool-tip:hover {
  cursor: pointer;
}

.amount-position {
  padding-right: 20px;
  float: right;
  font-weight: bold;
}

.discount {
  color: red;
}
